import React, { useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import NumericInput from 'react-numeric-input'

const Timer = (time) => (
  <CountdownCircleTimer
    isPlaying
    duration={time}
    size={300}
    strokeWidth={20}
    rotation="counterclockwise"
    colors={[
      ['#435b76', 0],
    ]}
  >
    {({ remainingTime }) => {
      let min = Math.floor(remainingTime / 60)
      let sec = remainingTime - min * 60
      return min + ":" + sec
    }}
  </CountdownCircleTimer>
)

const Configure = (currentCount, onSetCount) => (
  <div className={"config"}>
    <h3>Minuter</h3>
    <NumericInput min={0} max={60} value={currentCount} onChange={onSetCount} />
  </div>
)

function App() {
  const [count, setCount] = useState(5);
  const [running, setRunning] = useState(false)

  return (
    <div className="App">
      {!running && Configure(count, setCount)}
      {running && Timer(count * 60)}
      <button className="Btn"
        onClick={() => { setRunning(!running) }}>
        {running ? "Stop" : "Start"}
      </button>
    </div>
  );
}

export default App;
